<template>
  <v-container fluid>
    <v-card color="white">
      <v-layout row>
        <v-badge left overlap color="transparent">
          <v-card-text class="title">Form Fill-Up Summary</v-card-text>
        </v-badge>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-btn small fab color="#3F51B5" @click="searchCenterAllocationList()">
          <v-icon size="30" color="#fff">refresh</v-icon>
        </v-btn>
      </v-layout>
    </v-card>

    <v-layout wrap class="pb-5">
      <v-flex>
        <v-layout row align-left justify-left wrap fill-height>
          <v-flex md4
            ><v-card
              color="white"
              height="80"
              width="95%"
              style="
                border-radius: 10px;
                margin-right: 20px;
                margin-top: 20px;
                padding-right: 10px;
              "
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="#146064"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">date_range</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.ff_start_date">
                        {{ getFormFillupReport.data.ff_start_date }}
                      </span>
                      <span v-else>N/A</span>
                    </b>
                    <br />
                    <h6 id="titel_en">FORM FILL UP START DATE</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card></v-flex
          >
          <v-flex md4>
            <v-card
              color="white"
              height="80"
              width="95%"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="red"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">date_range</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.ff_end_date">
                        {{ getFormFillupReport.data.ff_end_date }}
                      </span>
                      <span v-else>N/A</span>
                    </b>
                    <br />
                    <h6 id="titel_en">FORM FILL UP END DATE</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex md4>
            <v-card
              color="white"
              height="80"
              width="95%"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="#146064"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">groups</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.total_probable">
                        {{ getFormFillupReport.data.total_probable }}
                      </span>
                      <span v-else>0</span>
                    </b>
                    <br />
                    <h6 id="titel_en">TOTAL PROBABLE</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex md4>
            <v-card
              color="white"
              height="80"
              width="95%"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="purple darken-3"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">touch_app</v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.total_applied">
                        {{ getFormFillupReport.data.total_applied }}
                      </span>
                      <span v-else>0</span>
                    </b>
                    <br />
                    <h6 id="titel_en">TOTAL APPLIED</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex md4>
            <v-card
              color="white"
              height="80"
              width="95%"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="green darken-3"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50"
                      >assignment_turned_in</v-icon
                    >
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.total_confirmed">
                        {{ getFormFillupReport.data.total_confirmed }}
                      </span>
                      <span v-else>0</span>
                    </b>
                    <br />
                    <h6 id="titel_en">TOTAL CONFIRMED</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex md4>
            <v-card
              color="white"
              height="80"
              width="95%"
              style="border-radius: 10px; margin-right: 20px; margin-top: 20px"
            >
              <v-layout
                column
                wrap
                fill-height
                align-start
                justify-start
                align-content-start
              >
                <v-card
                  color="orange darken-3"
                  height="80"
                  width="70"
                  style="border-radius: 10px 0 0 10px"
                >
                  <v-layout align-center justify-center row fill-height>
                    <v-icon color="white" size="50">room_preferences </v-icon>
                  </v-layout>
                </v-card>
                <v-card
                  flat
                  max-width="240"
                  width="240"
                  max-height="80"
                  height="80"
                >
                  <div class="ml-3" style="font-size: 2.2em">
                    <b>
                      <span v-if="getFormFillupReport.data.total_college">
                        {{ getFormFillupReport.data.total_college }}
                      </span>
                      <span v-else>0</span>
                    </b>
                    <br />
                    <h6 id="titel_en">TOTAL COLLEGE</h6>
                  </div>
                </v-card>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-card color="white">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title"
            >Payment Installment Wise Report</v-card-text
          >
        </v-card>
        <v-divider class="my-2" vertical></v-divider>

        <v-spacer />
      </v-layout>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="getFormFillupReport.data.payment_installment"
      :pagination.sync="pagination"
      :rows-per-page-items="pagination.rowsPerPageItems"
      :total-items="pagination.totalDataItems"
      class="elevation-4 mytable"
    >
      <template v-slot:items="props">
        <td class="text-xs-left">{{ props.index + 1 }}</td>
        <td class="text-xs-left">{{ props.item.title }}</td>
        <td class="text-xs-left">{{ props.item.no_of_installment }}</td>
        <td class="text-xs-left">
          {{ props.item.ff_start_date | dateFormat }}
        </td>
        <td class="text-xs-left">
          {{ props.item.ff_end_date | dateFormat }}
        </td>
        <td class="text-xs-left">
          {{ props.item.form_fill_up_summery.total_applied }}
        </td>
        <td class="text-xs-left">
          {{ props.item.form_fill_up_summery.total_confirmed }}
        </td>
      </template>
      <template v-slot:no-data> </template>
    </v-data-table>
    <br />

    <v-card color="white">
      <v-card-title color="grey">
        <h2>Search student summery</h2>
      </v-card-title>
      <v-layout row>
        <v-text-field
          tabindex="1"
          class="mt-0 fs-25px"
          flat
          hide-details
          label="REG No."
          prepend-inner-icon="search"
          single-line
          solo
          v-model="stdSummeryParam.reg_no"
          ref="reg_no_search"
        ></v-text-field>
        <v-card-actions>
          <v-btn color="info" dark @click="searchStudent">Search</v-btn>
        </v-card-actions>
      </v-layout>
    </v-card>
    <v-card>
      <div v-html="stdProbableSummery"></div>
    </v-card>
  </v-container>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
import commonGet from "../../mixin/common_getters_mixin";
import ICountUp from "vue-countup-v2";
export default {
  mixins: [commonGet, common_login_getters],
  components: { ICountUp },
  data() {
    return {
      show: false,
      updateCount: false,
      stdProbableSummery: "",
      optionsl: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        decimalPlaces: 0,
        prefix: "",
        suffix: ""
      },
      searchParam: {},
      stdSummeryParam: {},
      loading: false,
      headers: [
        { text: "SL", align: "left", sortable: false, value: "index" },
        { text: "Title", align: "left", value: "title" },
        { text: "Inst No.", align: "left", value: "no_of_installment" },
        { text: "Start Date", align: "left", value: "ff_start_date" },
        { text: "End Date", align: "left", value: "ff_end_date" },
        { text: "Student Applied", align: "left", value: "total_applied" },
        { text: "Teacher Confirmed", align: "left", value: "total_applied" }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 50,
        rowsPerPageItems: [50, 100, 200, 500]
      }
    };
  },

  created() {
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.exam_year = this.getLoggedInYear.year;
    this.searchCenterAllocationList();
    this.stdSummeryParam.exam_code = this.getLoggedInExam.code;
    this.stdSummeryParam.exam_year = this.getLoggedInYear.year;
  },

  computed: {
    getFormFillupReport() {
      return this.$store.getters.getFormFillupSummaryReport
        ? this.$store.getters.getFormFillupSummaryReport
        : [];
    }
  },

  watch: {
    getFormFillupReport(cv) {
      console.log(cv);
    }
  },

  methods: {
    searchCenterAllocationList() {
      this.loading = true;
      this.$store
        .dispatch("formFillupSummaryReport", this.searchParam)
        .then(() => {
          this.loading = false;
        });
    },
    searchStudent() {
      this.stdProbableSummery = "";
      this.loading = true;
      this.stdSummeryParam.view_type = "blade";
      this.$store
        .dispatch("searchStudentSummary", this.stdSummeryParam)
        .then(data => {
          this.stdProbableSummery = data;
        })
        .catch(error => {
          console.log("the error", error);
        });
    }
  }
};
</script>

<style lang="scss">
.iCountUp {
  font-size: 3em;
}
#titel_en {
  font-size: 16px !important;
}
</style>
